import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Box,
  Card,
  GridList,
  GridListTile,
} from "@material-ui/core";
import Link from "@components/Link";
import useWindowSize from "hooks/useWindowSize";
import Image from "next/image";
import theme from "@components/theme";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    backgroundColor: "#fafafa",
    paddingBottom: "70px",
  },
  card: {
    width: "200px",
    boxShadow: "none",
    marginBottom: "35px",
    // backgroundColor: theme.palette.primary,
    backgroundColor: "#fafafa",
    borderRadius: "2px",
  },
  image_color: {
    webkitFilter: "grayscale(100%)",
    filter: "grayscale(100%)",
    "&:hover": {
      webkitFilter: "grayscale(0%)",
      filter: "grayscale(0%)",
    },
  },
  list_container: {
    flexWrap: "nowrap",
    WebkitScrollSnapType: "none",
  },
  collab_list: {
    display: "flex",
    width: "100%",
    overflow: "hidden",
    justifyContent: "space-around",
    margin: "20px",
  },
  collab_container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: "20px",
  },
}));

const collabStations = [
  {
    url: "stations-map/parga-beach-resort-Preveza",
    img: "/images/collaborators/MAST.png",
    alt: "MAST Hotels and resorts, Parga beach resort Station",
  },
  {
    url: "stations-map/crowne-plaza-athens-city-centre-Michalakopoulou",
    img: "/images/collaborators/CrownePlaza.png",
    alt: "Crown Plaza Station, An IHG Hotel, Athens City Centre",
  },
  {
    url: "stations-map/holiday-inn-attica-avenue-Anatoliki",
    img: "/images/collaborators/HolidayInn.png",
    alt: "Holiday Inn Station",
  },
  {
    url: "stations-map/kipi-suites-Kipi",
    img: "/images/collaborators/AriaHotels.png",
    alt: "Aria Hotels, Kipi suites Station",
  },
  {
    url: "stations-map/dtmh-technologies-Saoumpert",
    img: "/images/collaborators/DTMH.png",
    alt: "DTMH Technologies Station",
  },
  {
    url: "/",
    img: "/images/collaborators/Artboard.png",
    alt: "",
  },
  {
    url: "/",
    img: "/images/collaborators/venture.png",
    alt: "",
  },
];

function CollabRibbon() {
  const classes = useStyles();
  const { t } = useTranslation();
  const size = typeof window !== "undefined" ? useWindowSize() : { width: 0 };

  const getResponsiveColumn = () => {
    if (size.width < 575) {
      return 1;
    } else if (size.width < 810) {
      return 2;
    } else if (size.width < 1100) {
      return 3;
    } else if (size.width < 1380) {
      return 4;
    } else {
      return 4;
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.collab_list}>
        <Box className={classes.collab_container}>
          <Typography
            gutterBottom
            component="h2"
            variant="h5"
            style={{ alignSelf: "center", marginBottom: "20px" }}
          >
            Proudly Collaborating with
          </Typography>
          <Swiper
            style={{
              "--swiper-navigation-color": theme.palette.primary.main,
              "--swiper-pagination-color": theme.palette.primary.main,
            }}
            slidesPerView={getResponsiveColumn()}
            // slidesPerView={3}
            spaceBetween={30}
            // slidesPerGroup={3}
            slidesPerGroup={getResponsiveColumn()}
            loop={false}
            loopFillGroupWithBlank={false}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            <GridList
              className={classes.list_container}
              cols={getResponsiveColumn()}
              cellHeight="auto"
            >
              {collabStations.map((station, index) => (
                <GridListTile
                  key={`collaborating-station-${index}`}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <SwiperSlide>
                    <Link href={station.url} naked>
                      <Card className={classes.card}>
                        <div
                          style={{
                            position: "relative",
                            width: "auto",
                            height: "100px",
                            borderRadius: "3px",
                            filter: `grayscale('100%')`,
                          }}
                        >
                          <Image
                            src={station.img}
                            title={station.alt}
                            alt={station.alt}
                            layout="fill"
                            objectFit="contain"
                            quality="40"
                            className={classes.image_color}
                          />
                        </div>
                      </Card>
                    </Link>
                  </SwiperSlide>
                </GridListTile>
              ))}
            </GridList>
          </Swiper>
        </Box>
      </div>
    </div>
  );
}


export default CollabRibbon;
